exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-buy-on-amazon-js": () => import("./../../../src/pages/buy-on-amazon.js" /* webpackChunkName: "component---src-pages-buy-on-amazon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-remote-support-js": () => import("./../../../src/pages/remote-support.js" /* webpackChunkName: "component---src-pages-remote-support-js" */),
  "component---src-pages-services-custom-built-computers-js": () => import("./../../../src/pages/services/custom-built-computers.js" /* webpackChunkName: "component---src-pages-services-custom-built-computers-js" */),
  "component---src-pages-services-data-backup-recovery-js": () => import("./../../../src/pages/services/data-backup-recovery.js" /* webpackChunkName: "component---src-pages-services-data-backup-recovery-js" */),
  "component---src-pages-services-hardware-updates-js": () => import("./../../../src/pages/services/hardware-updates.js" /* webpackChunkName: "component---src-pages-services-hardware-updates-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-laptop-repair-js": () => import("./../../../src/pages/services/laptop-repair.js" /* webpackChunkName: "component---src-pages-services-laptop-repair-js" */),
  "component---src-pages-services-mac-repair-js": () => import("./../../../src/pages/services/mac-repair.js" /* webpackChunkName: "component---src-pages-services-mac-repair-js" */),
  "component---src-pages-services-malware-virus-removal-js": () => import("./../../../src/pages/services/malware-virus-removal.js" /* webpackChunkName: "component---src-pages-services-malware-virus-removal-js" */),
  "component---src-pages-services-network-servers-js": () => import("./../../../src/pages/services/network-servers.js" /* webpackChunkName: "component---src-pages-services-network-servers-js" */),
  "component---src-pages-services-pc-repair-js": () => import("./../../../src/pages/services/pc-repair.js" /* webpackChunkName: "component---src-pages-services-pc-repair-js" */),
  "component---src-pages-services-software-installation-js": () => import("./../../../src/pages/services/software-installation.js" /* webpackChunkName: "component---src-pages-services-software-installation-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-job-agreement-index-tsx": () => import("./../../../src/templates/JobAgreement/index.tsx" /* webpackChunkName: "component---src-templates-job-agreement-index-tsx" */)
}

