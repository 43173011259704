/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onClientEntry = () => {
    window.onload = () => {
        console.log('window.onload');

        // Only run this code in production


        

        // var script = document.createElement('script');

        // script.onload = function () {
            
        //     console.log('do stuff with the script');
        //      window.dataLayer = window.dataLayer || [];
        //       function gtag(){dataLayer.push(arguments);}
        //       gtag('js', new Date());

        //       gtag('config', 'G-ZQ2JCXZ798');

        //     console.log('Analytics added');
        // };
        // script.src = 'https://www.googletagmanager.com/gtag/js?id=G-ZQ2JCXZ798';
        // document.head.append(script);
        
    }
    
  }